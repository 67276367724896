export var clubs = {
    "@bsc-buederich.de": {
        id: "01-0257",
        color: "#0085BE",
    },
    "@otv1893.de": {
        id: "01-0127",
        color: "#004519",
    },
    "@tsv-meerbusch.de": {
        id: "01-0987",
        color: "#0F204A",
    },
};
