import { createTheme, responsiveFontSizes } from "@mui/material";
import { clubs } from "./clubs";
export var getTheme = function (user) {
    var _a;
    var main = "#0085BE"; // Default color
    if (user === null || user === void 0 ? void 0 : user.email) {
        var key = (_a = user.email.match(/@.*/)) === null || _a === void 0 ? void 0 : _a.at(0);
        main = clubs[key].color;
    }
    return responsiveFontSizes(createTheme({
        palette: {
            primary: { main: main },
            info: { main: main },
        },
        typography: {
            button: {
                textTransform: "none",
            },
            fontSize: 16,
        },
    }));
};
