import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics, auth } from "./firebase";
export var useAuth = function () {
    var _a = useState(undefined), user = _a[0], setUser = _a[1];
    useEffect(function () {
        auth.onAuthStateChanged(function (user) {
            if (user) {
                setUser(user);
                logEvent(analytics, "login");
            }
            else {
                setUser(null);
            }
        });
    }, []);
    return { user: user };
};
export var useRouterAnalytics = function () {
    var location = useLocation();
    useEffect(function () {
        logEvent(analytics, "screen_view", {
            firebase_screen: location.pathname,
            firebase_screen_class: "react-router",
        });
    }, [location]);
};
