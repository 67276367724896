var getURL = function () {
    if (process.env.NODE_ENV === "production") {
        return {
            app: "https://nikolausturnier.bsc-buederich.de",
            display: "https://display.nikolausturnier.bsc-buederich.de",
            website: "https://www.bsc-buederich.de",
        };
    }
    return {
        app: "http://localhost:3000",
        display: "http://localhost:3001",
        website: "https://www.bsc-buederich.de",
    };
};
export var URL = getURL();
