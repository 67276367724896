import GoogleIcon from "@mui/icons-material/Google";
import {
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { handleLogin } from "@nikolausturnier/shared/src/firebase";
import { URL } from "@nikolausturnier/shared/src/config";

export const Login = () => {
  return (
    <Container sx={{ height: "100%", padding: 2 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <Stack>
          <Button
            onClick={handleLogin}
            startIcon={<GoogleIcon />}
            variant="contained"
          >
            <Typography variant="h6">Login with Google</Typography>
          </Button>
          <Typography color="gray" align="center" sx={{ pt: 1 }}>
            Made with ❤️ by{" "}
            <Link href={URL.website} target="_blank" underline="none">
              BSC Büderich
            </Link>
          </Typography>
        </Stack>
      </Grid>
    </Container>
  );
};
